import React from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { Home } from "./pages/home";
import introSlider1 from "./pages/introSlider1";
import { LogIn } from "./pages/logIn";
import { SignUp } from "./pages/signUp";
import ForgotPassword from "./pages/ForgotPassword";
import VerifyOTP from "./pages/VerifyOTP";
import ResetPassword from "./pages/ResetPassword";
import popups from "./pages/popups";
import support from "./pages/support";
import PurchaseHistory from "./pages/purchaseHistory";
import ChangePassword from "./pages/ChangePassword";
import { ChooseProduct } from "./pages/ChooseProduct";
import { Cart } from "./pages/Cart";
import DoorUnlocked from "./pages/DoorUnlocked";
import PaymentInfo from "./pages/Payment";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import { history } from "./_helpers/history";
import { PrivateRoute, PublicRoute } from "./_components";
import { Profile } from "./pages/Profile";
import { OrderSummary } from "./pages/OrderSummary";
import { Scanner } from "./pages/Scanner";
import Scanbutton from "./pages/Scanbutton";
import PendingAmount from "./pages/PendingAmount";
import FAQ from "./pages/FAQ";
import Termsofuse from "./pages/Termsofuse";
import { ManuallyCorrection } from "./pages/ManuallyCorrection";
import { ChooseMannualProduct } from "./pages/ChooseMannualProduct";
import NoLongerScreen from "./containers/MannualScreen/NoLongerScreen";
import MannualCorrected from "./containers/MannualScreen/MannualCorrected";
import ThankyouPage from "./containers/MannualScreen/ThankyouPage";
import {Logout} from "./pages/Logout";
import ContinueOption from "./pages/ContinueOption";
import CardList from "./pages/CardList";
import AddCard from "./pages/AddCard";
import Offer from "./pages/Offer";
import PaymentFail from "./pages/PaymentFail";
import PaymentSuccess from "./pages/PaymentSuccess";
import Rules from "./pages/Rules";
import License from "./pages/License";
import editPermission from "./pages/editPermission";
import PayBalance from "./pages/PayBalance";
import Loginwith from "./pages/Loginwith";
import SocialLogin from "./pages/SocialLogin";
import {SocialSignUp} from "./pages/SocialSignUp";
import { config } from "./_config";
import Receipt from "./pages/Receipt";
import ReceiptType from "./pages/ReceiptType";
import LinkPaytm from "./pages/LinkPaytm";
import VerifyPaytmOTP from "./pages/VerifyPaytmOTP";
import Setting from "./pages/Setting";
import OfferDetail from "./pages/OfferDetail";
import NeedHelp from "./pages/NeedHelp";
import CardFail from "./pages/CardFail";
import PointDetails from "./pages/PointDetails";
import Welcome from "./pages/Welcome";
import PolicyPage from "./pages/PolicyPage";
import { userService } from "./_services";
import {UnderMaintenance} from "./components/UnderMaintenance/UnderMaintenance"
import Survey from "./pages/Survey";
import SurveyResponse from "./components/SurveyResponse/SurveyResponse";
import Rewards from "./pages/Rewards";
import PaymentStatus from "./pages/PaymentStatus";
import LanguageSetting from "./pages/LanguageSetting";
import ReactGA from "react-ga4";
import { decryptData, encryptData } from "./_helpers/encryptData";
import LandingPage from "./pages/LandingPage";
import VerfiyToken from "./pages/verifyToken";
import Contact from "./pages/Contact";
import HurryForm from "./pages/Hurryform";
import EmailSent from "./pages/EmailSent";
import NewSignUp from "./pages/NewSignUp";
import { getAppId } from "./_helpers/getAppId";
import LoyaltyTerms from "./pages/LoyaltyTerms";
class App extends React.Component {
  IsMaintenance=false
  constructor(props) {
    
    super(props);
    // this.state={
    //   isLoading:true
    // }
    history.listen((location, action) => {
      // clear alert on location change
      // this.props.clearAlerts();
    });
    // let status=false;
    // let user = JSON.parse(localStorage.getItem("user"));
    // if(user){  
    //   let expDate = new Date(user.expiry)
    //   let currDate = new Date();
    //   currDate.setHours(currDate.getHours());
    //   currDate = currDate.getTime()
    //   expDate = expDate.getTime();
    //   if(expDate>currDate){
    //     status=true;
    //   }
    // }
    // this.state = {
    //   IsMaintenance:false
    // }
    // document.title="Coca-Cola "+config.countryName
    this.checkfun()
    if(localStorage.getItem("user")){
      this.increaseExpiry()
    }
    setInterval(()=>{
      this.checkfun()
    },5000)
    // google analytics
    if(config.GoogleAnalyticsId!=""){
      ReactGA.initialize(config.GoogleAnalyticsId);
      }
    let checkenc = localStorage.getItem("user");
    let rew = localStorage.getItem("reward");
    let pending = localStorage.getItem("pending");
    if(checkenc || rew || pending){        
      if((checkenc && checkenc.includes("success")) || (rew && rew.includes("success")) || (pending && pending.includes("success"))){
        let lang = localStorage.getItem('i18nextLng');
        localStorage.clear();
        localStorage.setItem('i18nextLng',lang);
      }
    }
    const isEncrypted = localStorage.getItem('isEncrypted')
    const cdsEnabled = localStorage.getItem('cdsEnabled')
    if(!isEncrypted && cdsEnabled!="true"){
      let lang = localStorage.getItem('i18nextLng');
      // localStorage.clear();
      localStorage.setItem('i18nextLng',lang);

    }
  }
  increaseExpiry=()=>{
    let user = JSON.parse(decryptData(localStorage.getItem("user")));
    let hrs = new Date();
    hrs.setMinutes(hrs.getMinutes() + config.sessionExpiryTime);
    user.expiry = hrs;
    localStorage.setItem('user', encryptData(JSON.stringify(user)));
  }
  checkfun=async()=>{
    const x = await userService.checkMaintenance()
    const status = sessionStorage.getItem('isworking')
    this.IsMaintenance=x;
    if(x){
      if(!status){
        sessionStorage.setItem('isworking','TRUE')
        window.location.reload();
      }
    }
    else{
      sessionStorage.removeItem('isworking')
    }
  }

  render() {
    const AppId = getAppId()
    return (
      <Router history={history}>
        {
          this.IsMaintenance ?
          <Switch>
            <Route exact path="*" component={UnderMaintenance}/>{" "}
          </Switch>
          :
          AppId == 20 ? 
          <Switch>
            <Route
              path={`${process.env.PUBLIC_URL + "/ContinueOption"}`}
              component={ContinueOption}
            />{" "}
            <Route render={() => <Redirect to="/ContinueOption" />} />{" "}
          </Switch>
        :
          AppId!=8 ?
        <Switch>
          <PrivateRoute exact path="/Home" component={Home} />{" "}
          <Route
            path={`${process.env.PUBLIC_URL + "(\/\/|\/)continueoption-tvm"}`}
            component={LandingPage}
          />{" "}
          <Route
            path={`${process.env.PUBLIC_URL + "/introSlider1"}`}
            component={introSlider1}
          />{" "}
          <PublicRoute
            path={`${process.env.PUBLIC_URL + "/loginwith"}`}
            component={Loginwith}
          />{" "}
          <PublicRoute
            path={`${process.env.PUBLIC_URL + "/socialLogin"}`}
            component={SocialLogin}
          />{" "}
          <PublicRoute
            path={`${process.env.PUBLIC_URL + "/socialsignup"}`}
            component={SocialSignUp}
          />{" "}
          <PrivateRoute
            path={`${process.env.PUBLIC_URL + "/popups"}`}
            component={popups}
          />{" "}
          <PrivateRoute
            path={`${process.env.PUBLIC_URL + "/welcome"}`}
            component={Welcome}
          />{" "}
          <PrivateRoute
            path={`${process.env.PUBLIC_URL + "/support"}`}
            component={support}
          />{" "}
          <PrivateRoute
            path={`${process.env.PUBLIC_URL + "/edit-permission"}`}
            component={editPermission}
          />{" "}
          <Route
            path={`${process.env.PUBLIC_URL + "/FAQ"}`}
            component={FAQ}
          />{" "}
          <Route
            path={`${process.env.PUBLIC_URL + "/viewlicense"}`}
            component={License}
          />{" "}
          <Route
            path={`${process.env.PUBLIC_URL + "/Terms-of-use"}`}
            component={Termsofuse}
          />{" "}
          <PrivateRoute
            path={`${process.env.PUBLIC_URL + "/PurchaseHistory"}`}
            component={PurchaseHistory}
          />{" "}
          <PrivateRoute
            path={`${process.env.PUBLIC_URL + "/ChooseProduct"}`}
            component={ChooseProduct}
          />{" "}
          <PrivateRoute
            path={`${process.env.PUBLIC_URL + "/OrderSummary/:id"}`}
            component={OrderSummary}
          />{" "}
          <PrivateRoute
            path={`${process.env.PUBLIC_URL + "/Scanner"}`}
            component={Scanner}
          />{" "}
          <PrivateRoute
            path={`${process.env.PUBLIC_URL + "/Scanbutton"}`}
            component={Scanbutton}
          />
          <PrivateRoute
            path={`${process.env.PUBLIC_URL + "/Cart"}`}
            component={Cart}
          />{" "}
          <PrivateRoute
            path={`${process.env.PUBLIC_URL + "/Profile"}`}
            component={Profile}
          />{" "}
          <PrivateRoute
            path={`${process.env.PUBLIC_URL + "/DoorUnlocked"}`}
            component={DoorUnlocked}
          />{" "}
          <PrivateRoute
            path={`${process.env.PUBLIC_URL + "/PaymentInfo"}`}
            component={PaymentInfo}
          />{" "}          
          <PrivateRoute
            path={`${process.env.PUBLIC_URL + "/offer"}`}
            component={Offer}
          />{" "}
          <PrivateRoute
            path={`${process.env.PUBLIC_URL + "/offer-detail/:offertype/:offerid"}`}
            component={OfferDetail}
          />{" "}
          <PrivateRoute
            path={`${process.env.PUBLIC_URL + "/point-detail"}`}
            component={PointDetails}
          />{" "}
          <PrivateRoute
            path={`${process.env.PUBLIC_URL + "/offerRules"}`}
            component={Rules}
          />{" "}
          <PrivateRoute
            path={`${process.env.PUBLIC_URL + "/Card"}`}
            component={CardList}
          />{" "}
          <PrivateRoute
            path={`${process.env.PUBLIC_URL + "/AddCard"}`}
            component={AddCard}
          />{" "}
          <PrivateRoute
            path={`${process.env.PUBLIC_URL + "/CardFail"}`}
            component={CardFail}
          />{" "}
          <PrivateRoute
            path={`${process.env.PUBLIC_URL + "/PayOutstandingbalance/:id"}`}
            component={PayBalance}
          />{" "}
          <PrivateRoute
            path={`${process.env.PUBLIC_URL + "/invoice-receipt"}`}
            component={Receipt}
          />{" "}
          <PrivateRoute
            path={`${process.env.PUBLIC_URL + "/receipt-type"}`}
            component={ReceiptType}
          />{" "}
          <PrivateRoute
            path={`${process.env.PUBLIC_URL + "/link-with-paytm"}`}
            component={LinkPaytm}
          />{" "}
          <PrivateRoute
            path={`${process.env.PUBLIC_URL + "/verify-paytm-otp"}`}
            component={VerifyPaytmOTP}
          />{" "}
          <PrivateRoute
            path={`${process.env.PUBLIC_URL + "/Setting"}`}
            component={Setting}
          />{" "}
          <PrivateRoute
            path={`${process.env.PUBLIC_URL + "/LanguageSetting"}`}
            component={LanguageSetting}
          />{" "}
          <PrivateRoute
            path={`${process.env.PUBLIC_URL + "/NeedHelp/:orderId?"}`}
            component={NeedHelp}
          />{" "}
          <PrivateRoute
            path={`${process.env.PUBLIC_URL + "/Rewards"}`}
            component={Rewards}
          />{" "}
          <PrivateRoute
            path={`${process.env.PUBLIC_URL + "/payment-status"}`}
            component={PaymentStatus}
          />{" "}
          <Route
            path={`${process.env.PUBLIC_URL + "/Logout"}`}
            component={Logout}
          />{" "}
          <Route
            path={`${process.env.PUBLIC_URL + "/contact"}`}
            component={Contact}
          />{" "}
          <Route
            path={`${process.env.PUBLIC_URL + "/policy/:documentType"}`}
            component={PolicyPage}
          />{" "}
          <PublicRoute
            path={`${process.env.PUBLIC_URL + "/logIn"}`}
            component={LogIn}
          />{" "}
          <PublicRoute
            path={`${process.env.PUBLIC_URL + "(\/\/|\/)ContinueOption"}`}
            component={ContinueOption}
          />{" "}
          <PublicRoute
            path={`${process.env.PUBLIC_URL + "/signUp"}`}
            component={SignUp}
          />{" "}
          <Route
            path={`${process.env.PUBLIC_URL + "/token-verification"}`}
            component={VerfiyToken}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/forgotPassword"}`}
            component={ForgotPassword}
          />{" "}
          <Route
            path={`${process.env.PUBLIC_URL + "/VerifyOTP"}`}
            component={VerifyOTP}
          />{" "}
          <Route
            path={`${process.env.PUBLIC_URL + "/ResetPassword"}`}
            component={ResetPassword}
          />{" "}
          <Route
            path={`${process.env.PUBLIC_URL + "/changepassword"}`}
            component={ChangePassword}
          />{" "}
          <Route
            path={`${process.env.PUBLIC_URL + "/successResponse"}`}
            component={PaymentSuccess}
          />{" "}
          <Route
            path={`${process.env.PUBLIC_URL + "/errorResponse"}`}
            component={PaymentFail}
          />{" "}
          <Route
            path={`${process.env.PUBLIC_URL + "/PrivacyPolicy"}`}
            component={PrivacyPolicy}
          />{" "}
          <Route
            path={`${process.env.PUBLIC_URL + "/PendingAmount"}`}
            component={PendingAmount}
          />{" "}
          <Route
            path={`${process.env.PUBLIC_URL + "/ManuallyCorrection"}`}
            component={ManuallyCorrection}
          />{" "}
          <Route
            path={`${process.env.PUBLIC_URL + "/ChooseMannualProduct"}`}
            component={ChooseMannualProduct}
          />{" "}
          <Route
            path={`${process.env.PUBLIC_URL + "/NoLongerScreen"}`}
            component={NoLongerScreen}
          />{" "}
          <Route
            path={`${process.env.PUBLIC_URL + "/MannualCorrected"}`}
            component={MannualCorrected}
          />{" "}
          <Route
            path={`${process.env.PUBLIC_URL + "/ThankyouPage"}`}
            component={ThankyouPage}
          />{" "}
          <Route
            path={`${process.env.PUBLIC_URL + "/consumer-survey"}`}
            component={Survey}
          />{" "}
          <Route
            path={`${process.env.PUBLIC_URL + "/consumer-survey-success"}`}
            component={SurveyResponse}
          />{" "}
          <Route
            path={`${process.env.PUBLIC_URL + "/consumer-survey-error"}`}
            component={SurveyResponse}
          />{" "}
          <Route
            path={`${process.env.PUBLIC_URL + "/loyaltyterms"}`}
            component={LoyaltyTerms}
          />{" "}
          <Route render={() => <Redirect to="/socialLogin" />} />{" "}

          
        </Switch>
        :
        <Switch>
          <Route
            path={`${process.env.PUBLIC_URL + "/hurryform"}`}
            component={HurryForm}
          />{" "}
          <Route
            path={`${process.env.PUBLIC_URL + "/emailsent"}`}
            component={EmailSent}
          />{" "}
          <Route
            path={`${process.env.PUBLIC_URL + "/NewSignUp"}`}
            component={NewSignUp}
          />{" "}
          <Route
            path={`${process.env.PUBLIC_URL + "/ContinueOption"}`}
            component={ContinueOption}
          />{" "}
          <Route render={() => <Redirect to="/ContinueOption" />} />{" "}
        </Switch>
        }
      </Router>
    );
  }
}
export default App;
