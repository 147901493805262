import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { history } from '../_helpers';
import cokeandgo from "../assets/images/landing/cokeandgo.png";
import logo from "../assets/images/landing/white-logo.png";
import rewarding_logo from "../assets/images/landing/rewarding_logo.png";
import rewarding_logo_german from "../assets/images/landing/rewarding_logo_german.png";
import rewarding_logo_czech from "../assets/images/landing/rewarding_logo_czech.png";
import square_logo from "../assets/images/landing/icon/square-logo.png";
import phoneimage from "../assets/images/landing/icon/activated_img.png";
import firstIcon from "../assets/images/landing/icon/01.png";
import secondIcon from "../assets/images/landing/icon/02.png";
import thirdIcon from "../assets/images/landing/icon/03.png";
import fourthIcon from "../assets/images/landing/icon/04.png";
import fifthIcon from "../assets/images/landing/icon/05.png";
import mobimage from "../assets/images/landing/icon/mob-group.png";
import playstore from "../assets/images/landing/playstore.png";
import apple from "../assets/images/landing/apple.png";
import "../assets/scss/landing.scss";
import { config } from '../_config';
import moment from 'moment';
import { isAndroid, isIOS} from "react-device-detector";
import { getAppId } from "../_helpers/getAppId";
import queryString from 'query-string';
import i18next from "i18next";
const ApplicationTypeId = getAppId();
export default class LandingPage extends Component {
    constructor(props){
        super(props)       
        const urlpart = this.props.location.search.slice(1)
        let {qr:qrcode="", coolerType="",assetType=""} = queryString.parse(this.props.location.search);
        this.qrcode = qrcode;
        if(window.location.origin == 'https://cokeandgo-stg-austria.instagng.com' && (qrcode=='12:00:00:00:00:06')){
            fetch("https://api.ipify.org?format=json")
                .then((json) => { return json.json() })
                .then(async (result) => {
                    const ipAddress = result.ip;
                    const pageName = window.location.href + ';' + 'https://stories.onelink.me/URjs/gk6ey1eh';
                    const applicationTypeId = 3001;
                    const requestOptions = {
                        method: "POST",
                        headers: { "Content-Type": "application/x-www-form-urlencoded" },
                        body: new URLSearchParams({ ipAddress, applicationTypeId, pageName }),
                    };
        
                    await fetch(`${config.envUrl}VendingAppApi/appSiteHitLog`, requestOptions)
                        .then((response) => response.json())
                        .then((data) => {
                            console.log(data);
                            window.location.href='https://stories.onelink.me/URjs/gk6ey1eh';
                        })
                        .catch((error) => {
                            console.error("Error logging site hit:", error);
                            window.location.href='https://stories.onelink.me/URjs/gk6ey1eh';
                        });
                })
                .catch((error) => {
                    console.error("Error logging site hit:", error);
                    window.location.href='https://stories.onelink.me/URjs/gk6ey1eh';
                });
        }
        else{
            fetch("https://api.ipify.org?format=json")
                .then((json)=>{return json.json()})
                .then(async(result)=>{
                    const ipAddress = result.ip;
                    const pageName = window.location.href
                    const applicationTypeId = ApplicationTypeId;
                    const requestOptions = {
                        method: "POST",
                        headers: { "Content-Type": "application/x-www-form-urlencoded" },
                        body: new URLSearchParams({ ipAddress,applicationTypeId,pageName }),
                    };
                    await fetch(
                        `${config.envUrl}VendingAppApi/appSiteHitLog`,
                        requestOptions
                    )
                        .then((response) => {
                        return response.json();
                        })
                        .then((data) => {
                            console.log(data)
                        })
                })
        }
        this.handleRedirect = this.handleRedirect.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }
    handleRedirect(){
        let checkOnboarding = localStorage.getItem('onBoarding');
        if(checkOnboarding){            
            history.push('/Link');
        }
        else{            
            history.push('/introSlider1');
        }
    }
    handleClick(){
        if(isAndroid){
            window.location.href = ApplicationTypeId == 12 ? "https://play.google.com/store/apps/details?id=com.cch.aut.cokeandgo" : ApplicationTypeId == 24 ? "https://play.google.com/store/apps/details?id=com.cch.ie.cokeandgo" : "https://play.google.com/store/apps/details?id=com.cch.cz.cokeandgo"
        }
        else if(isIOS){
            window.location.href = ApplicationTypeId == 12 ? "https://apps.apple.com/us/app/coke-go-austria/id6448452246" : ApplicationTypeId == 24 ? "https://apps.apple.com/us/app/coke-go-ireland/id6448452380" : "https://apps.apple.com/us/app/coke-go-czech/id6478109526"
        }
        else{
            window.location.href = ApplicationTypeId == 12 ? "https://play.google.com/store/apps/details?id=com.cch.aut.cokeandgo" : ApplicationTypeId == 24 ? "https://play.google.com/store/apps/details?id=com.cch.ie.cokeandgo" : "https://play.google.com/store/apps/details?id=com.cch.cz.cokeandgo"
        }
    }
    render() {
        if (window.location.origin == 'https://cokeandgo-stg-austria.instagng.com' && (this.qrcode=='12:00:00:00:00:06')){
            return null
        }
        let year = moment().format("YYYY")
        return (
            <>
                <div className="main-page">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="top-Header">
                                    <div className="">
                                        <img src={cokeandgo} width="179" height="52" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mb-5">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 position-none">
                                    <h1 className="landing_title mb-0 text-left">{i18next.t("Unlock refreshment")}</h1> 
                                    {/* <h1 className="landing_title mb-0 d-md-none text-left">{i18next.t("Unlock refreshment")}</h1>  */}
                                    <button className='btn btn-landing btn-top d-md-none' onClick={this.handleClick}>
                                    {i18next.t("Download App")}
                                    </button>
                                    <div className='mobgroup d-md-none'>
                                        <img src={mobimage} />
                                    </div>
                                    <div className='landing_sub_title d-none d-md-block'>
                                        {
                                            ApplicationTypeId == 32 ? (
                                            <img src={rewarding_logo_czech} />) 
                                            : ApplicationTypeId == 12 ? (
                                            <img src={rewarding_logo_german} />)
                                            : (
                                            <img src={rewarding_logo} />)
                                        }
                                    </div>
                                    <div className="phoneImage d-none d-md-block">
                                        <img src={phoneimage} />
                                    </div>
                                    <div className='d-flex d-md-none' style={{marginLeft:'10%'}}>
                                        <div className='cokelogo mt-3'>
                                            <img src={square_logo} height="80" />
                                        </div>
                                        <div className='cokedesc pl-3 mt-4'> 
                                            <h4 className='mb-0'>Coke&GO</h4>
                                            <h6>{i18next.t("Rewarding refreshment")}</h6>
                                        </div>
                                    </div>
                                    <div className='features'>
                                        <div className='row'>
                                            <div className='col-md-5'>
                                                <h4>{i18next.t("Features")}:</h4>
                                                <ul>
                                                    <li>{i18next.t("Conveniently purchase Coke&GO products with touchless pay.")}</li>
                                                    <li>{i18next.t("Enjoy a clean, convenient, touch-free experience")}</li>
                                                    <li>{i18next.t("Cool rewards. Earn points every time you purchase a drink.")}</li>
                                                    <li>{i18next.t("Great variety. Choose from a wide range of your Coke favorites.")}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-12'>
                                    <div className="mt-5 d-none d-md-block">
                                        <div className="download" style={{marginLeft:"10%"}}>
                                            <h6>{i18next.t("Download Coke&GO now")}</h6>
                                            <a
                                                href =  {
                                                        ApplicationTypeId == 32 ? (
                                                            'https://play.google.com/store/apps/details?id=com.cch.cz.cokeandgo') 
                                                        : ApplicationTypeId == 12 ? (
                                                            'https://play.google.com/store/apps/details?id=com.cch.aut.cokeandgo')
                                                        : (
                                                            'https://play.google.com/store/apps/details?id=com.cch.ie.cokeandgo')
                                                }
                                            >
                                                <img src={playstore} />
                                            </a>
                                            <a 
                                                href = {
                                                        ApplicationTypeId == 32 ? (
                                                            'https://apps.apple.com/us/app/coke-go-czech/id6478109526') 
                                                        : ApplicationTypeId == 12 ? (
                                                            'https://apps.apple.com/us/app/coke-go-austria/id6448452246')
                                                        : (
                                                            'https://apps.apple.com/us/app/coke-go-ireland/id6448452380')
                                                }
                                            >
                                                <img src={apple} className="ml-2" />
                                            </a>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mob-landing-footer mt-5 d-md-none">
                            <div className="">
                                <div className='btn btn-bottom d-md-none mob-button' onClick={this.handleClick}>{i18next.t("Download App")}</div>
                            </div>
                        </div>
                    <div className='bg-grey'>
                        <div className='container'>
                            <div className="basic_details_flex">
                                <div><a className=""><img src={firstIcon}  alt=""/><span>01</span>{i18next.t("Download the Coke&GO app from your favorite app store.")}</a></div>
                                <div><a className=""><img src={secondIcon}  alt=""/><span>02</span>{i18next.t("Create a Coke&GO account and add your payment information.")}</a></div>
                                <div><a className=""><img src={thirdIcon}  alt=""/><span>03</span>{i18next.t("Scan the unique QR code on the vending machine to make a purchase")}</a></div>
                                <div><a className=""><img src={fourthIcon}  alt=""/><span>04</span>{i18next.t("Tap to choose your favorite beverage and confirm payment")}</a></div>
                                <div><a className=""><img src={fifthIcon}  alt=""/><span>05</span>{i18next.t("Collect your beverage and enjoy")}</a></div>
                            </div>
                        </div>
                    </div>
                    <div className="footer">                        
                        <div className="container">
                            <div className="row">
                                <div className="col-md-8 offset-md-2 text-center">
                                    <img src={logo} />
                                    <p className="my-4 text-white">© {year} The Coca-Cola Company. {ApplicationTypeId == 32 ? i18next.t("All rights reserved.") : ApplicationTypeId == 12 ? i18next.t("All rights reserved.") : i18next.t("All rights reserved.")}</p>                                
                                </div>
                                <div className="col-md-12 text-center">
                                <ul className="footerList">
                                        <li>
                                            <a href= { ApplicationTypeId == 12 ? ("https://at.coca-colahellenic.com/en/contact-us") : ApplicationTypeId == 24 ? ("https://www.coca-cola.com/ie/en/about-us/contact-us") : ("https://www.coca-cola.com/cz/cs/about-us/contact-us") }>{i18next.t("Contact US")}</a>
                                        </li>
                                        <li>
                                            <a href= { ApplicationTypeId == 12 ? ("https://www.coca-cola-oesterreich.at/datenschutz") : ApplicationTypeId == 24 ? ("https://www.coca-cola.com/ie/en/legal/privacy-policy") : ("https://www.coca-cola.com/cz/cs/legal/privacy-policy") }>{i18next.t("Privacy Policy")}</a>
                                        </li>
                                        {/* <li>
                                            <a href="https://us.coca-cola.com/privacy-policy">do not sell my personal information</a>
                                        </li> */}
                                        <li>
                                            {
                                                ApplicationTypeId == 24 ?
                                                <a href = "https://www.coca-cola.com/ie/en/legal/terms-of-service">{i18next.t("terms & conditions")}</a>
                                                : (ApplicationTypeId == 12 || ApplicationTypeId == 32) ?
                                                <Link to={config.termsOfUse}>{i18next.t("terms & conditions")}</Link>
                                                :
                                                <a href = "https://www.coca-cola.com/ie/en/legal/terms-of-service">{i18next.t("terms & conditions")}</a>
                                            }
                                        </li>
                                        {/* <li>
                                            <a href="https://us.coca-cola.com/privacy-policy#california-collection">California notice at collection</a>
                                        </li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
